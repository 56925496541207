<template>
  <b-sidebar
    id="edit-moderation-user-motivation-sidebar"
    :visible="isPanelModerationEditActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-panel-moderation-edit-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          модерация доп мотивации пользователя
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="validationContext"
            name=" "
            rules="required|statusValid"
          >
            <b-form-group
              label="Модуль"
              label-for="status"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="elementData.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="status"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-if="elementData.status === 'forbidden' || elementData.status === 'denial'"
            #default="validationContext"
            name="  "
            :rules="`${elementData.status === 'forbidden' ? 'required' : ''}`"
          >
            <b-form-group
              label="Комментарий"
              label-for="comments"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="comments"
                v-model="elementData.comments"
                trim
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Изменить
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Отмена
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
// import { useToast } from 'vue-toastification/composition'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
// import { required } from '@validations'
import { required, statusValid } from '@core/utils/validations/validations'
import moderationService from '@/application/moderationService'

localize('ru', ru)

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    BButton,

    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isPanelModerationEditActive',
    event: 'update:is-panel-moderation-edit-active',
  },
  props: {
    isPanelModerationEditActive: {
      type: Boolean,
      required: true,
    },
    motivationUserId: {
      type: Number,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
  },
  methods: {
    onSubmit() {
      this.updateElement(this.motivationUserId, this.elementData).then(() => {
        this.$emit('update:is-panel-moderation-edit-active', false)
        this.$emit('refetch-data')
        this.$swal({
          icon: 'success',
          title: 'Обработка заявки!',
          text: 'Заявка успешно обработана.',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      })
    },
  },
  setup({ status }) {
    const blankData = {
      status: status || 'create',
      comments: '',
    }

    const elementData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      elementData.value = JSON.parse(JSON.stringify(blankData))
    }

    // ToDo Паределать на выборку из базы
    const statusOptions = [
      { label: 'Выберите статус', value: null },
      { label: 'Создана заявка', value: 'create' },
      { label: 'Заблокирован', value: 'forbidden' },
      { label: 'Отказ', value: 'denial' },
      { label: 'Заявка принята', value: 'accept' },
    ]

    const { updateElement } = moderationService()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)
    return {
      required,
      statusValid,

      elementData,
      statusOptions,

      refFormObserver,
      getValidationState,
      resetForm,

      updateElement,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
