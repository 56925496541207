<template>
  <div>
    <panel-moderation-edit
      :is-panel-moderation-edit-active.sync="isPanelModerationEditActive"
      :status="(userData ? userData.status : '')"
      :motivation-user-id="motivationUserId"
      @refetch-data="refetchData"
    />
    <b-alert
      v-if="errorData"
      variant="danger"
      :show="!userData"
    >
      <h4 class="alert-heading">
        Ошибка при извлечении данных
      </h4>
      <div class="alert-body">
        {{ errorData }} Проверьте
        <b-link
          class="alert-link"
          :to="{ name: 'moderation-bonuses'}"
        >
          лист модерации
        </b-link>
        для других пользователей.
      </div>
    </b-alert>

    <template v-if="userData">
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <user-view-user-info-card :user-data="userData.user" />
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <user-view-user-motivation-edit-card
            :user-data="userData"
            @openPanel="isPanelModerationEditActive = true"
          />
        </b-col>
      </b-row>
      <user-view-user-motivation-data-fields-card
        :fields-data="userData.fields"
        title="Данные для проверки (зафиксированные)"
      />
      <user-view-user-motivation-data-fields-card
        :fields-data="userData.realFields"
        title="Текущие данные"
      />
    </template>

  </div>
</template>

<script>
import router from '@/router'
import { ref } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import UserViewUserInfoCard from '@/components/user/UserViewUserInfoCard.vue'
import UserViewUserMotivationEditCard from '@/components/user/UserViewUserMotivationEditCard.vue'
import UserViewUserMotivationDataFieldsCard from '@/components/user/UserViewUserMotivationDataFieldsCard.vue'
import moderationService from '@/application/moderationService'
import PanelModerationEdit from './PanelModerationEdit.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    UserViewUserInfoCard,
    UserViewUserMotivationEditCard,
    UserViewUserMotivationDataFieldsCard,
    PanelModerationEdit,
  },
  setup() {
    const motivationUserId = ref(parseInt(router.currentRoute.params.id, 10))
    const isPanelModerationEditActive = ref(false)

    const {
      userData,
      errorData,
      getElement,
    } = moderationService()

    const refetchData = () => {
      getElement(router.currentRoute.params.id).then()
    }

    getElement(router.currentRoute.params.id).then()
    return {
      motivationUserId,
      isPanelModerationEditActive,
      userData,
      errorData,
      refetchData,
    }
  },
}
</script>
