<template>
  <b-card :title="title">
    <b-row>
      <b-col
        v-for="(item, index) in fieldsData"
        :key="index"
        cols="12"
        md="4"
      >
        <b-form-group
          :label="item.key"
          :label-for="item.key"
        >
          <b-form-input
            :id="item.key"
            :value="item.value"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { generateRandomVariant } from '@/utils/UserFilter'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  props: {
    fieldsData: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      generateRandomVariant,
      avatarText,
    }
  },
}
</script>
