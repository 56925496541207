<template>
  <b-card>
    <b-row>
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="userData.avatar ? userData.avatar.path : null"
            :text="avatarText(`${ userData.firstName } ${ userData.lastName || '' }`)"
            :variant="`light-${generateRandomVariant()}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ `${ userData.firstName } ${ userData.lastName || '' }` }}
              </h4>
              <span class="card-text">{{ userData.phone }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'apps-users-view', params: { id: userData.id } }"
                variant="primary"
              >
                Перейти в профиль
              </b-button>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="AwardIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ userData.balance }} бонусов
              </h5>
              <small>Бонусный баланс</small>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { generateRandomVariant } from '@/utils/UserFilter'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      generateRandomVariant,
      avatarText,
    }
  },
}
</script>
